import { api, getBillReport } from 'api/report';

import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { cloneDeep } from 'lodash-es';
import dateUtils from '@/utils/dateutils.js';
import { myMixin } from '@/mixins/mixins.js';
import optionData from '@/assets/js/optionData';
import { filterObject } from 'utils/utils';
import { mapState, mapActions } from 'vuex';
import qs from 'querystring';
export default {
  mixins: [myMixin],
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      pageParam: {
        page: 1,
        pageSize: 20,
      },
      dataParam: null,
      affiliateChecked: true,
      offerChecked: false,
      sourceChecked: false,
      fullWidth: document.documentElement.clientWidth,
      sourceOfferIdChecked: false,
      affSubsChecked: false,
      categoryChecked: false,
      packageChecked: false,
      carriersChecked: false,
      countriesChecked: false,
      monthChecked: false,
      dateChecked: true,
      hourChecked: false,
      affReportList: [],
      total: null,
      listLoading: false,
      radioOptions: ['payout', 'revenue', 'conversions'],
      historyDeductList: [],
      summariesMap: {},
      startToEndDate: '',
      timezoneOption: optionData.timezoneOption,
      categoryOption: optionData.categoryOption,
      sourceIdArray: [],
      affiliateIdArray: [],
      category: '',
      affReport: {
        affiliateIds: null,
        countries: null,
        pkgs: null,
        affSubs: null,
        sourceIds: null,
        sourceOfferIds: null,
        offerIds: null,
        offerName: null,
        fromDate: null,
        toDate: null,
        timezone: 0,
        columns: [],
        sorting: 'revenue',
        comparing: true,
        carriers: null,
      },
    };
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    listenChange() {
      const {
        affiliateChecked,
        sourceChecked,
        offerChecked,
        packageChecked,
        countriesChecked,
        categoryChecked,
        sourceOfferIdChecked,
        carriersChecked,
        affSubsChecked,
      } = this;
      return {
        affiliateChecked,
        sourceChecked,
        offerChecked,
        packageChecked,
        countriesChecked,
        categoryChecked,
        sourceOfferIdChecked,
        carriersChecked,
        affSubsChecked,
      };
    },
  },
  mounted() {
    //默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.startToEndDate = [];
    this.startToEndDate[0] = dateUtils.date2StrYMD(end);
    this.startToEndDate[1] = dateUtils.date2StrYMD(start);

    this.getAffiliateList();
    this.getSourceIdsList();
  },
  watch: {
    listenChange: function() {
      this.$nextTick(() => {
        this.$refs.tableRef.doLayout();
      });
    },
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateList']),
    ...mapActions('source', ['getSourceIdsList']),
    searchListClick(curPage) {
      if (curPage) {
        this.pageParam.page = curPage;
      }
      let param = {
        ...this.affReport,
        ...this.pageParam,
      };

      if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
        param.sourceIds = param.sourceIds.join(',');
      } else {
        delete param.sourceIds;
      }
      if (Array.isArray(param.affiliateIds) && param.affiliateIds.length > 0) {
        param.affiliateIds = param.affiliateIds.join(',');
      } else {
        delete param.affiliateIds;
      }
      if (
        this.offerChecked == true &&
        param.columns.indexOf('offerId') == -1 &&
        param.columns.indexOf('offerName') == -1
      ) {
        param.columns.push('offerId');
        param.columns.push('offerName');
      } else if (
        this.offerChecked == false &&
        param.columns.indexOf('offerId') !== -1 &&
        param.columns.indexOf('offerName') !== -1
      ) {
        const offerIdIndex = param.columns.findIndex((item) => {
          if (item == 'offerId') {
            return true;
          }
        });
        param.columns.splice(offerIdIndex, 1);
        const offerNameIndex = param.columns.findIndex((item) => {
          if (item == 'offerName') {
            return true;
          }
        });
        param.columns.splice(offerNameIndex, 1);
      }

      if (this.affiliateChecked == true && param.columns.indexOf('affiliateId') == -1) {
        param.columns.push('affiliateId');
      } else if (this.affiliateChecked == false && param.columns.indexOf('affiliateId') !== -1) {
        const affiliateIndex = param.columns.findIndex((item) => {
          if (item == 'affiliateId') {
            return true;
          }
        });
        param.columns.splice(affiliateIndex, 1);
      }

      if (this.sourceChecked == true && param.columns.indexOf('sourceId') == -1) {
        param.columns.push('sourceId');
      } else if (this.sourceChecked == false && param.columns.indexOf('sourceId') !== -1) {
        const sourceIdIndex = param.columns.findIndex((item) => {
          if (item == 'sourceId') {
            return true;
          }
        });
        param.columns.splice(sourceIdIndex, 1);
      }

      if (this.countriesChecked == true && param.columns.indexOf('country') == -1) {
        param.columns.push('country');
      } else if (this.countriesChecked == false && param.columns.indexOf('country') !== -1) {
        const countryIndex = param.columns.findIndex((item) => {
          if (item == 'country') {
            return true;
          }
        });
        param.columns.splice(countryIndex, 1);
      }

      if (this.carriersChecked == true && param.columns.indexOf('carrier') == -1) {
        param.columns.push('carrier');
      } else if (this.carriersChecked == false && param.columns.indexOf('carrier') !== -1) {
        const carrierIndex = param.columns.findIndex((item) => {
          if (item == 'carrier') {
            return true;
          }
        });
        param.columns.splice(carrierIndex, 1);
      }

      if (this.packageChecked == true && param.columns.indexOf('pkg') == -1) {
        param.columns.push('pkg');
      } else if (this.packageChecked == false && param.columns.indexOf('pkg') !== -1) {
        const prodIndex = param.columns.findIndex((item) => {
          if (item == 'pkg') {
            return true;
          }
        });
        param.columns.splice(prodIndex, 1);
      }

      if (this.affSubsChecked == true && param.columns.indexOf('affSub') == -1) {
        param.columns.push('affSub');
      } else if (this.affSubsChecked == false && param.columns.indexOf('affSub') !== -1) {
        const affSubIndex = param.columns.findIndex((item) => {
          if (item == 'affSub') {
            return true;
          }
        });
        param.columns.splice(affSubIndex, 1);
      }

      if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
        param.columns.push('month');
      } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
        const monthIndex = param.columns.findIndex((item) => {
          if (item == 'month') {
            return true;
          }
        });
        param.columns.splice(monthIndex, 1);
      }

      if (this.dateChecked == true && param.columns.indexOf('date') == -1) {
        param.columns.push('date');
      } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
        const dateIndex = param.columns.findIndex((item) => {
          if (item == 'date') {
            return true;
          }
        });
        param.columns.splice(dateIndex, 1);
      }

      if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
        param.columns.push('hour');
      } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
        const hourIndex = param.columns.findIndex((item) => {
          if (item == 'hour') {
            return true;
          }
        });
        param.columns.splice(hourIndex, 1);
      }

      if (this.sourceOfferIdChecked == true && param.columns.indexOf('sourceOfferId') == -1) {
        param.columns.push('sourceOfferId');
      } else if (
        this.sourceOfferIdChecked == false &&
        param.columns.indexOf('sourceOfferId') !== -1
      ) {
        const sourceOfferIdIndex = param.columns.findIndex((item) => {
          if (item == 'sourceOfferId') {
            return true;
          }
        });
        param.columns.splice(sourceOfferIdIndex, 1);
      }

      if (this.categoryChecked == true && param.columns.indexOf('category') == -1) {
        param.columns.push('category');
      } else if (this.categoryChecked == false && param.columns.indexOf('category') !== -1) {
        const categoryIndex = param.columns.findIndex((item) => {
          if (item == 'category') {
            return true;
          }
        });
        param.columns.splice(categoryIndex, 1);
      }

      if (this.startToEndDate.length === 2) {
        let startDate = this.startToEndDate[0];
        let endDate = this.startToEndDate[1];
        param.fromDate = startDate;
        param.toDate = endDate;
      }

      this.pageParam.page = 1;

      const dataParam = cloneDeep(param);
      if (Array.isArray(dataParam.columns)) {
        dataParam.columns = dataParam.columns.join(',');
      }
      console.log(dataParam.columns, 'columns');
      this.dataParam = dataParam;
      this.getBillReport();
    },
    getBillReport() {
      this.listLoading = true;
      let param = {
        ...this.dataParam,
        ...this.pageParam,
      };
      param = filterObject(param);
      getBillReport(param).then((response) => {
        this.listLoading = false;
        if (response.code === 200) {
          if (response.result.length == 0) {
            this.$message({
              message: '数据列表为空',
              type: 'warning',
            });
          }

          const summariesMap = response.result.pop() || [];
          const result = response.result || [];
          const affReportList = result?.map((item) => {
            if (item.affiliateTrafficTag) {
              const arr = item.affiliateTrafficTag.split('-');

              item.affColor =
                arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';

              item.affiliateTrafficTag = arr[1];
            }
            if (item.affSubTrafficTag) {
              const arr = item.affSubTrafficTag.split('-');
              item.affSubColor =
                arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';
              item.affSubTrafficTag = arr[1];
            }
            return item;
          });
          this.summariesMap = summariesMap;
          this.affReportList = affReportList;
          this.total = response.total;
          let totalObject = new Object();
          totalObject.date = 'Total';
          totalObject.conversions = this.summariesMap.conversions;
          totalObject.lastConversions = this.summariesMap.lastConversions;
          totalObject.affConversions = this.summariesMap.affConversions;
          totalObject.totalAffPayout = this.summariesMap.totalAffPayout;
          totalObject.lastTotalPayout = this.summariesMap.lastTotalPayout;
          totalObject.totalRevenue = this.summariesMap.totalRevenue;
          totalObject.lastTotalRevenue = this.summariesMap.lastTotalRevenue;
          totalObject.sourceOfferId = '';
          this.affReportList.push(totalObject);
        } else {
          this.affReportList = [];
          this.$message.error('Error: ' + response.message);
        }
      });
    },
    exportExcelFileClick() {
      let param = {};
      let columns = new Array();

      if (this.affiliateChecked == true && columns.indexOf('affiliateId') == -1) {
        columns.push('affiliateId');
      } else if (this.affiliateChecked == false && columns.indexOf('affiliateId') !== -1) {
        const affiliateIdIndex = columns.findIndex((item) => {
          if (item == 'affiliateId') {
            return true;
          }
        });
        columns.splice(affiliateIdIndex, 1);
      }

      if (this.affiliateChecked == true && columns.indexOf('affiliateName') == -1) {
        columns.push('affiliateName');
      } else if (this.affiliateChecked == false && columns.indexOf('affiliateName') !== -1) {
        const affiliateNameIndex = columns.findIndex((item) => {
          if (item == 'affiliateName') {
            return true;
          }
        });
        columns.splice(affiliateNameIndex, 1);
      }

      if (this.sourceChecked == true && columns.indexOf('sourceId') == -1) {
        columns.push('sourceId');
      } else if (this.sourceChecked == false && columns.indexOf('sourceId') !== -1) {
        const sourceIdIndex = columns.findIndex((item) => {
          if (item == 'sourceId') {
            return true;
          }
        });
        columns.splice(sourceIdIndex, 1);
      }

      if (this.sourceChecked == true && columns.indexOf('sourceName') == -1) {
        columns.push('sourceName');
      } else if (this.sourceChecked == false && columns.indexOf('sourceName') !== -1) {
        const sourceNameIndex = columns.findIndex((item) => {
          if (item == 'sourceName') {
            return true;
          }
        });
        columns.splice(sourceNameIndex, 1);
      }

      if (this.offerChecked == true && columns.indexOf('offerId') == -1) {
        columns.push('offerId');
      } else if (this.offerChecked == false && columns.indexOf('offerId') !== -1) {
        const offerIdIndex = columns.findIndex((item) => {
          if (item == 'offerId') {
            return true;
          }
        });
        columns.splice(offerIdIndex, 1);
      }

      if (this.offerChecked == true && columns.indexOf('offerName') == -1) {
        columns.push('offerName');
      } else if (this.offerChecked == false && columns.indexOf('offerName') !== -1) {
        const offerNameIndex = columns.findIndex((item) => {
          if (item == 'offerName') {
            return true;
          }
        });
        columns.splice(offerNameIndex, 1);
      }

      if (this.affSubsChecked == true && columns.indexOf('affSub') == -1) {
        columns.push('affSub');
      } else if (this.affSubsChecked == false && columns.indexOf('affSub') !== -1) {
        const affSubIndex = columns.findIndex((item) => {
          if (item == 'affSub') {
            return true;
          }
        });
        columns.splice(affSubIndex, 1);
      }

      if (this.sourceOfferIdChecked == true && columns.indexOf('sourceOfferId') == -1) {
        columns.push('sourceOfferId');
      } else if (this.sourceOfferIdChecked == false && columns.indexOf('sourceOfferId') !== -1) {
        const sourceOfferIdIndex = columns.findIndex((item) => {
          if (item == 'sourceOfferId') {
            return true;
          }
        });
        columns.splice(sourceOfferIdIndex, 1);
      }

      if (this.countriesChecked == true && columns.indexOf('country') == -1) {
        columns.push('country');
      } else if (this.countriesChecked == false && columns.indexOf('country') !== -1) {
        const countryIndex = columns.findIndex((item) => {
          if (item == 'country') {
            return true;
          }
        });
        columns.splice(countryIndex, 1);
      }

      if (this.packageChecked == true && columns.indexOf('pkg') == -1) {
        columns.push('pkg');
      } else if (this.packageChecked == false && columns.indexOf('pkg') !== -1) {
        const pkgIndex = columns.findIndex((item) => {
          if (item == 'pkg') {
            return true;
          }
        });
        columns.splice(pkgIndex, 1);
      }

      if (this.carriersChecked == true && columns.indexOf('carrier') == -1) {
        columns.push('carrier');
      } else if (this.carriersChecked == false && columns.indexOf('carrier') !== -1) {
        const carrierIndex = columns.findIndex((item) => {
          if (item == 'carrier') {
            return true;
          }
        });
        columns.splice(carrierIndex, 1);
      }

      if (this.monthChecked == true && columns.indexOf('month') == -1) {
        columns.push('month');
      } else if (this.monthChecked == false && columns.indexOf('month') !== -1) {
        const monthIndex = columns.findIndex((item) => {
          if (item == 'month') {
            return true;
          }
        });
        columns.splice(monthIndex, 1);
      }

      if (this.dateChecked == true && columns.indexOf('date') == -1) {
        columns.push('date');
      } else if (this.dateChecked == false && columns.indexOf('date') !== -1) {
        const dateIndex = columns.findIndex((item) => {
          if (item == 'date') {
            return true;
          }
        });
        columns.splice(dateIndex, 1);
      }

      if (this.hourChecked == true && columns.indexOf('hour') == -1) {
        columns.push('hour');
      } else if (this.hourChecked == false && columns.indexOf('hour') !== -1) {
        const hourIndex = columns.findIndex((item) => {
          if (item == 'hour') {
            return true;
          }
        });
        columns.splice(hourIndex, 1);
      }

      if (this.categoryChecked == true && columns.indexOf('category') == -1) {
        columns.push('category');
      } else if (this.dateChecked == false && columns.indexOf('category') !== -1) {
        const categoryIndex = columns.findIndex((item) => {
          if (item == 'category') {
            return true;
          }
        });
        columns.splice(categoryIndex, 1);
      }

      param['fromDate'] = this.startToEndDate[0];
      param['toDate'] = this.startToEndDate[1];
      if (this.affReport.timezone != null) {
        param['timezone'] = this.affReport.timezone;
      }

      if (this.affReport.sourceIds != null && this.affReport.sourceIds.length > 0) {
        param['sourceIds'] = this.affReport.sourceIds + '';
      }

      if (
        this.affReport.affiliateIds !== null &&
        this.affReport.affiliateIds.length !== 0 &&
        this.affReport.affiliateIds !== []
      ) {
        param['affiliateIds'] = this.affReport.affiliateIds + '';
      }
      if (this.affReport.affSubs != null) {
        param['affSubs'] = this.affReport.affSubs + '';
      }

      if (this.affReport.offerIds != null) {
        param['offerIds'] = this.affReport.offerIds;
      }
      if (this.affReport.countries != null) {
        param['countries'] = this.affReport.countries;
      }

      if (this.category != null && this.category.length > 0) {
        param['category'] = escape(this.category);
      }
      if (this.affReport.sourceOfferIds != null) {
        param['sourceOfferIds'] = this.affReport.sourceOfferIds;
      }
      if (this.affReport.pkgList != null) {
        param['pkgList'] = this.affReport.pkgList;
      }
      if (this.affReport.carriers != null) {
        param['carriers'] = this.affReport.carriers;
      }
      if (columns != null) {
        param['columns'] = columns.join(',');
      }
      if (this.affReport.offerName != null) {
        param['offerName'] = encodeURI(this.affReport.offerName);
      }
      console.log(param);
      param = filterObject(param);
      let strParam = qs.stringify(param);
      window.open(`${api.DOWNLOAD_BILL_REPORT}?${strParam}`, '_blank');
    },
    affiliatetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/affiliate/detail',
        query: {
          affiliateId: row.affiliateId,
        },
      });
      window.open(href, '_blank');
    },
    filterNull(val) {
      return val == null ? 0 : val;
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.pageParam.page = currentPage;
      this.getBillReport();
    },
    offerdetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/offer/detail',
        query: {
          offerId: row.offerId,
        },
      });
      window.open(href, '_blank');
    },
    sourcedetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/source/detail',
        query: {
          sourceId: row.sourceId,
        },
      });
      window.open(href, '_blank');
    },
  },
};
