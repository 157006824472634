<template>
  <div class="billreport">
    <!-- <div class="main-Title bgff"><h2>Aff Report</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="affReport" label-width="80px" size="mini">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="OfferId(s)" label-width="70px" class="mb5">
                <el-input
                  v-model="affReport.offerIds"
                  placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="OfferName" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="affReport.offerName"
                      placeholder="OfferName: eg. Mobile Strike"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="AffiliateId(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <group-select
                      v-model="affReport.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      filterable
                      reserve-keyword
                      collapse-tags
                      class="w100"
                    />
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="affiliateChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Source(s)" label-width="70px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <group-select
                      v-model="affReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      clearable
                      reserve-keyword
                      filterable
                      collapse-tags
                      size="small"
                      class="w100"
                    />
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="sourceChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="SourceOfferId" label-width="100px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="affReport.sourceOfferIds"
                      placeholder="source offer id here"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="sourceOfferIdChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Aff_Sub" label-width="60px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="affReport.affSubs"
                      placeholder="eg. carry_i or carry_i,carry_j"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="affSubsChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Countries" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input v-model="affReport.countries" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="countriesChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Carriers" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input v-model="affReport.carriers" placeholder="eg. carrier"></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="carriersChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Package" label-width="70px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="affReport.pkgList"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="packageChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Categories" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select v-model="category" multiple filterable collapse-tags class="w100">
                      <el-option
                        v-for="item in categoryOption"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="categoryChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label-width="" label="TimeZone" class="mb5">
                <el-select v-model="affReport.timezone" class="w100">
                  <el-option
                    v-for="item in timezoneOption"
                    :key="item.value"
                    :value="item.number"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Interval" label-width="70px" class="mb5" style="height: 37px;">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Month" class="mb0">
                      <el-checkbox v-model="monthChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Date" class="mb0">
                      <el-checkbox v-model="dateChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Hour" class="mb0">
                      <el-checkbox v-model="hourChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Date" label-width="50px" class="mb5">
                <el-date-picker
                  v-model="startToEndDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  class="w100"
                  align="right"
                  unlink-panels
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="6">
              <el-form-item label="Sort by" label-width="60px" class="mb5">
                <el-radio-group v-model="affReport.sorting">
                  <el-radio v-for="item in radioOptions" :key="item" :label="item" class="mr10">{{
                    item
                  }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="12" align="right">
              <el-form-item label-width="0px" class="mb5">
                <el-button
                  type="primary"
                  size="mini"
                  :loading="listLoading"
                  @click="searchListClick(1)"
                  >In Browser</el-button
                >
                <el-button type="primary" size="mini" @click="exportExcelFileClick"
                  >Download Excel</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          ref="tableRef"
          style="width:100%;"
          stripe
          border
          size="mini"
          :data="affReportList"
          highlight-current-row
        >
          <el-table-column
            label="Date"
            prop="date"
            fixed="left"
            align="center"
            width="170"
            key="date"
          ></el-table-column>
          <el-table-column
            label="Affiliate ID"
            :fixed="fullWidth <= 768 ? false : 'left'"
            min-width="100"
            align="center"
            v-if="affiliateChecked"
            key="affiliateId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateId
              }}</span>
              <el-tag
                v-if="scope.row.affiliateTrafficTag"
                :color="scope.row.affColor"
                style="color:white;height:24px;line-height:24px;"
              >
                {{ scope.row.affiliateTrafficTag }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Affiliate Name"
            :fixed="fullWidth <= 768 ? false : 'left'"
            width="140"
            align="center"
            v-if="affiliateChecked"
            key="affiliateName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source ID"
            v-if="sourceChecked"
            width="100"
            align="center"
            key="sourceId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source Name"
            width="140"
            align="center"
            v-if="sourceChecked"
            key="sourceName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Aff Sub"
            prop="affSub"
            width="150"
            align="center"
            v-if="affSubsChecked"
            key="affSub"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.affSub }}</span>
              <el-tag
                v-if="scope.row.affSubTrafficTag && scope.row.affSub"
                :color="scope.row.affSubColor"
                style="color:white;height:24px;line-height:24px;"
              >
                {{ scope.row.affSubTrafficTag }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer ID"
            align="center"
            v-if="offerChecked"
            width="110"
            key="offerId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer Name"
            v-if="offerChecked"
            width="140"
            align="center"
            show-overflow-tooltip
            key="offerName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer Status"
            prop="offerStatus"
            width="130"
            align="center"
            v-if="offerChecked"
            key="offerStatus"
          ></el-table-column>
          <el-table-column
            label="Prod Name"
            prop="pkg"
            width="120"
            align="center"
            v-if="packageChecked"
            key="pkg"
          ></el-table-column>
          <el-table-column
            label="Country"
            prop="country"
            width="100"
            align="center"
            v-if="countriesChecked"
            key="country"
          ></el-table-column>
          <el-table-column
            label="Carrier"
            prop="carrier"
            width="70"
            align="center"
            v-if="carriersChecked"
            key="carrier"
          ></el-table-column>
          <el-table-column
            label="Category"
            prop="category"
            width="90"
            align="center"
            v-if="categoryChecked"
            key="category"
          ></el-table-column>
          <el-table-column
            label="Conversions"
            min-width="110"
            prop="conversions"
            align="center"
            key="conversions"
          ></el-table-column>
          <el-table-column
            label="Conversions(扣后)"
            align="center"
            min-width="140"
            prop="affConversions"
            key="affConversions"
          ></el-table-column>
          <el-table-column
            label="Payout"
            prop="totalAffPayout"
            align="center"
            min-width="80"
            key="totalAffPayout"
          ></el-table-column>
          <el-table-column
            label="Revenue"
            prop="totalRevenue"
            min-width="80"
            align="center"
            key="totalRevenue"
          ></el-table-column>
          <el-table-column
            label="SourceOfferId"
            v-if="sourceOfferIdChecked"
            width="120"
            align="center"
            key="sourceOfferId"
          >
            <template slot-scope="scope">
              <span>{{ filterNull(scope.row.sourceOfferId) }}</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import BillReportCon from '../controllers/report/BillReport';
  export default {
    name: 'BillReport',
    ...BillReportCon,
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
